import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";
import Header from "../header";
import Banner from "../banner";
import WorkFlow from "../workflow";
import OtherRequestOptions from "../otherReqOptions";
import Support from "../support";
import Footer from "../footer";
import SamsungContainer from "../SamsungContainer/index";
import SamsungFooter from "../SamsungFooter/index";
import { PARTNER_COUNTRY, PARTNER_DETAILS } from "../../utils/constants";
import "./layout.scss";
import { gtmPageView } from "./../../utils/utilities";
import Popover from "../Popover";
import NoteComponent from "../note";
import SubmitClaim from "../submitClaim";

function Layout({ children }) {
  const ChatComponent = loadable(() => import("../chat"));
  const [linksVisible, setLinksVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    gtmPageView();
  });

  const setPopoverVisible = () => {
    setVisible(!visible);
    setLinksVisible(!visible);
  };

  const isPopoverRequired = () => {
    return process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.HK || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MO
  }

  const renderPageByPartner = (partner) => {
    if (partner === PARTNER_DETAILS.SAMSUNG) {
      return (
        <div className="samsung" style={{ position: `relative` }}>
          {isPopoverRequired() && (<Popover linksVisible={linksVisible} />)}
          <div className={`w-100 page-content overflow-hidden`}>
            <SamsungContainer />
            <ChatComponent />
          </div>
          <div className={`w-100 page-footer`}>
            <SamsungFooter setPopoverVisible={setPopoverVisible} />
          </div>
        </div>
      );
    } else {
      return (
        <>
          <Header />
          <Banner />
          {(process.env.GATSBY_COUNTRY !== PARTNER_COUNTRY.TW && process.env.GATSBY_PARTNER !== PARTNER_DETAILS.HOMECREDIT_ID) && (<ChatComponent />)}
          {(process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MY || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MYDIGI) && process.env.GATSBY_PARTNER === PARTNER_DETAILS.BOLTTECH && (
            <NoteComponent />
          )}
          {(process.env.GATSBY_PARTNER !== PARTNER_DETAILS.EIR && process.env.GATSBY_PARTNER !== PARTNER_DETAILS.TRE) && (<WorkFlow />)}
          {!(process.env.GATSBY_PARTNER === PARTNER_DETAILS.ELECBOY) && (process.env.GATSBY_PARTNER !== PARTNER_DETAILS.EIR && process.env.GATSBY_PARTNER !== PARTNER_DETAILS.SINGTEL && process.env.GATSBY_PARTNER !== PARTNER_DETAILS.TRE) && (<Support />)}
          {(process.env.GATSBY_PARTNER === PARTNER_DETAILS.EIR || process.env.GATSBY_PARTNER === PARTNER_DETAILS.SINGTEL || process.env.GATSBY_PARTNER === PARTNER_DETAILS.TRE) && (<SubmitClaim />)}
          <OtherRequestOptions />
          {!(process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT || process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT_ID) && (
            <Footer />
          )}
        </>
      );
    }
  };
  return (
    <div>
      {children}
      {renderPageByPartner(process.env.GATSBY_PARTNER)}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
